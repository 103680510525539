<template>
	<photo img="/img/e9/p1/prefecture" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: 'Prefecture',
		components: {
			photo
		},
		data (){
			return {
				points: [
                    {
						link: 'E9.P1.Posgrade',
						name: 'Posgrado',
						left: -3.9, //'-125px',
						top: 38.7, //'300px',
						width: 7.5, //'250px',
						height: 17.7 //'160px'
					},
                    {
						link: 'E9.P1.21',
						name: 'Siglo XXI',
						left: 72, //'2300px',
						top: 36, //'300px',
						width: 5.2, //'200px',
						height: 24.7 //'160px'
					},
                    {
						link: 'E9.PB.Direction',
						name: 'Gobierno',
						left: 50.5, //'1640px',
						top: 49.4, //'400px',
						width: 7.5, //'175px',
						height: 22 //'160px'
					},
                    {
						link: 'E9.P2.Celex',
						name: 'Planta 2',
						left: 42, //'1440px',
						top: 35, //'200px',
						width: 8, //'175px',
						height: 35 //'160px'
					},
				]
			}
		}
	}
</script>
