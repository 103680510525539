<template>
	<photo img="/img/e9/p1/posgrade" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: 'Prefecture',
		components: {
			photo
		},
		data (){
			return {
				points: [
                    {
						link: 'E9.P1.Prefecture',
						name: 'Salones',
						left: -5.1, //'-150px',
						top: 44.7, //'340px',
						width: 7.9, //'250px',
						height: 17.7 //'160px'
					},
				]
			}
		}
	}
</script>
